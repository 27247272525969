import ApiService from '../common/api.service'
import StorageService from '../common/storage.service'
import decrypt from '../common/decrypt'

class DashboardService {
  dashboardInfo(data) {
    return ApiService.query('dashboard',data).then(response => decrypt(response.data.data), error => Promise.reject(error))
  }
  
  getTotalTransectionByStatus(data) {
    return ApiService.query('total/transection/status',data).then(response => decrypt(response.data.data), error => Promise.reject(error))
  }
  
  getTopFiveOperator(data){
    return ApiService.query('top-five/operator',data).then(response => decrypt(response.data.data), error => Promise.reject(error))

  }

  getLastFiveTransection(){
    return ApiService.get('latest/five/transection').then(response => decrypt(response.data.data), error => Promise.reject(error))

  }
}

export default new DashboardService()
